import React from "react"
import useUser from "hooks/useUser"

const AdminLayout = React.lazy(() => import("layouts/Admin"))
const AuthLayout = React.lazy(() => import("layouts/Auth"))

export default function App() {
  const user = useUser()

  if (user) return <AdminLayout />
  return <AuthLayout />
}
