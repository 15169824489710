import React from "react";
import AuthProvider from "./auth";

import PropTypes from "prop-types";

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function AppProvider({ children }) {
  return <AuthProvider>{children}</AuthProvider>;
}
