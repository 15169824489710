/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Copyright 2019 Benchmark (https://benchmark.ae)

* Coded by Benchmark

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Suspense } from "react"
import ReactDOM from "react-dom"
import { BrowserRouter } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import AppProvider from "context"
import App from "app"
import api from "api"

import "assets/plugins/nucleo/css/nucleo.css"
import "@fortawesome/fontawesome-free/css/all.min.css"
import "assets/scss/argon-dashboard-react.scss"
import "react-toastify/dist/ReactToastify.css"
import { SWRConfig } from "swr"

ReactDOM.render(
  <Suspense fallback="...">
    <BrowserRouter>
      <AppProvider>
        <ToastContainer />
        <SWRConfig
          value={{
            refreshInterval: 0,
            fetcher: api,
          }}
        >
          <App />
        </SWRConfig>
      </AppProvider>
    </BrowserRouter>
  </Suspense>,
  document.getElementById("root")
)
